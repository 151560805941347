<script>
import SearchService from "@/services/search.service";
import AdoptionsService from "@/services/adoptions.service";

export default {
  name: "AdoptionClientSearch",
  data() {
    return {
      searchStr: "",
      reports:[],
      clients:[],
      loading:false,
      panel: 0,
      searched:false,
    };
  },
  methods:{
    async search(){
      this.loading=true
      await SearchService.getSearchResults(this.searchStr).then((res) => {
        this.clients = res.filter(e=>e.managing_firm == this.entity);
        this.searched=true
      }).finally(()=>{
        this.loading=false
      });
    },
    routeToStat(status){
      this.$emit('loadReport', status);
    },
    async getReport(clientId){
      this.loading=true
      await AdoptionsService.getAdoptionStats(this.entity, clientId).then((res) => {
        this.clients = [];
        this.searchStr = "";
        this.searched=false
        this.reports = res.filter(e=>e.count > 0);
        if (!this.reports.length) {
          this.$func.popMessage('No reports found for given client!', 'danger')
        }
      }).finally(() => {
        this.loading = false
      });
    }
  },
  props: {
    entity: {
      type: String,
      default: null,
    },
  },
}
</script>

<template>
  <v-row justify="center" class="px-12 pt-4">
    <v-col class="d-flex ">
    <v-text-field
        v-model="searchStr" label="Client Search"
        placeholder="Search Clients by EIN,Name or primary email" outlined dense
        @keydown.enter="search"
        class="col-12 col-md-6 mr-8" />
    <v-btn @click="search" color="primary" :disabled="!searchStr || loading">Search</v-btn>
    </v-col>

    <v-expansion-panels  :value="panel">
      <v-expansion-panel  expand v-model="panel"  v-if='searched'>
        <v-expansion-panel-header>
          Choose a client
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div v-if="!clients.length">
            <v-alert type="warning" outlined>
              No clients found
            </v-alert>
          </div>
          <v-list v-else>
            <v-list-item-group >
              <v-list-item v-if="loading">
                <v-progress-linear
                    class="mt-10"
                    :size="70"
                    :width="7"
                    color="purple"
                    indeterminate
                ></v-progress-linear>
              </v-list-item>
              <v-list-item v-else @click="getReport(client.id)" v-for="client in clients" :key="client.id">
                <v-list-item-title>{{client.id}}</v-list-item-title>
                <v-list-item-title>{{client.legal_name}}</v-list-item-title>
                <v-list-item-subtitle>{{client.trade_name}}</v-list-item-subtitle>
                <v-list-item-subtitle>{{client.ein}}</v-list-item-subtitle>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel  :value="panel" v-if="reports.length">
        <v-expansion-panel-header>
          Reports that client is currently in
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list-item-group >
            <v-list-item v-if="loading">
              <v-progress-linear
                  class="mt-10"
                  :size="70"
                  :width="7"
                  color="purple"
                  indeterminate
              ></v-progress-linear>
            </v-list-item>
            <v-list-item v-else @click="routeToStat(item.status)" v-for="item in reports" :key="item.status">
              <v-list-item-title>{{item.name}}</v-list-item-title>
              <v-list-item-title  class="d-flex justify-center"><span  :class="{'number' : item.count > 0}">{{item.count}}</span></v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>

<style scoped>
.number {
  width: 30px;  /* Adjust based on your needs */
  height: 30px;
  background-color: #4CAF50;  /* Green color */
  border-radius: 50%;  /* Makes it circular */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;  /* Makes the number white */
}
</style>
