<script>
import AdoptionsService from "@/services/adoptions.service";
import MoveBackDialog from "@/components/SinglePostAdoptFirm/MoveBackDialog.vue";

export default {
  name: "ContactInitiated",
  components: { MoveBackDialog },
  props: {
    entityId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    status: 5,
    loading: false,
    clients: [],
    confirmLoading: false,
    moveBackDialogVisible: false,
    headers: [
      {
        text: 'Client',
        value: 'trade_name',
        filterable: true
      },
      {
        text: 'EIN',
        value: 'ein',
        filterable: true
      },
      {
        text: 'Legal Name',
        value: 'legal_name',
        filterable: true
      },
      {
        text: 'Date contact initiated',
        value: 'date',
      }
    ],
    selectedClients: []
  }),
  methods: {
    async confirmContacts() {
      this.confirmLoading = true;
      const payload = {
        status: 6
      }

      for (let i = 0; i < this.selectedClients.length; i++) {
        let client = this.selectedClients[i]
        await AdoptionsService.patchAdoption(client.adoption_id, payload)
      }

      this.$store.dispatch('snackbar/showSnackbarMessage',
        {message: 'Contact(s) confirmed!', duration: 6000})
      this.confirmLoading = false;
      this.selectedClients = []
      await this.getData()
    },
    async getData() {
      this.loading = true

      try {
        this.clients = await AdoptionsService.getAdoptionStatus(this.entityId, this.status)
      } finally {
        this.loading = false
      }
    },
  },
  async mounted() {
    await this.getData()
  }
};
</script>

<template>
  <div>
    <MoveBackDialog
      v-if="moveBackDialogVisible"
      @update:visible="event => moveBackDialogVisible = event"
      @success="getData"
      :visible="moveBackDialogVisible"
      :status="status"
      :statusName="`Contact Initiated`"
      :clients="selectedClients"
    />
    <v-row>
      <v-col cols="12" md="10">
        <v-card>
          <v-card-title>
            <div class="d-flex justify-end" style="width: 100%">
              <v-btn color="primary" :disabled="!selectedClients.length" @click="moveBackDialogVisible = true">
                Move Back
              </v-btn>
            </div>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="clients"
              show-select
              v-model="selectedClients"
              :loading="loading"
              disable-pagination
              hide-default-footer
            >
              <template v-slot:item.date="{ item }">
                {{ new Date(new Date().setDate(new Date().getDate() - item.days_in_status)).toLocaleDateString() }}
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <div class="d-flex justify-center" style="width: 100%">
              <v-btn color="primary" :loading="confirmLoading" @click="confirmContacts" :disabled="!selectedClients.length">Confirm contact </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>

</style>