<script>
import AdoptionsService from "@/services/adoptions.service";
import MoveBackDialog from "@/components/SinglePostAdoptFirm/MoveBackDialog.vue";

export default {
  name: "BadContact",
  components: { MoveBackDialog },
  props: {
    entityId: {
      type: Number,
      required: true
    }
  },
  data:  () => ({
    search: '',
    status: 4,
    loading: false,
    selected: [],
    moveBackDialogVisible: false,
    headers: [
      {
        text: 'Client',
        filterable: true,
        value: 'trade_name'
      },
      {
        text: 'EIN',
        filterable: true,
        value: 'ein'
      },
      {
        text: 'Legal Name',
        filterable: true,
        value: 'legal_name'
      }
    ],
    clients: []
  }),
  methods: {
    handleRowClick(item) {
      this.$router.push(`/clients/${item.id}`)
    },

    async getData() {
      this.loading = true

      try {
        this.clients = await AdoptionsService.getAdoptionStatus(this.entityId, this.status, 2)
      } finally {
        this.loading = false
      }
    },
  },
  async mounted() {
    this.getData()
  },
};
</script>

<template>
  <div>
    <MoveBackDialog
      v-if="moveBackDialogVisible"
      @update:visible="event => moveBackDialogVisible = event"
      @success="getData"
      :visible="moveBackDialogVisible"
      :status="status"
      :statusName="`Bad Contact`"
      :clients="selected"
      :initial-payload="{
        email_sent: 0
      }"
    />
    <v-row>
      <v-col cols="12" md="10">
        <v-card>
          <v-card-title>
            <v-row align="center">
              <v-col>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="auto">
                <v-btn color="primary" :disabled="!selected.length" @click="moveBackDialogVisible = true">
                  Move Back
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headers"
              @click:row="handleRowClick"
              :items="clients"
              :loading="loading"
              :search="search"
              v-model="selected"
              show-select
              disable-pagination
              hide-default-footer
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>

</style>
