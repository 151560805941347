<script>
import AdoptionsService from "@/services/adoptions.service";

export default {
  name: "UnresponsiveClients",
  props: {
    entityId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    loading: false,
    confirmLoading: false,
    status: 7,
    clients: [],
    headers: [
      {
        text: 'Client',
        value: 'client',
        filterable: true
      },
      {
        text: 'EIN',
        value: 'ein',
        filterable: true
      },
      {
        text: 'Legal Name',
        value: 'legal_name',
        filterable: true
      },
      {
        text: 'Date contact initiated',
        value: 'date',
      }
    ],
    selectedClients: []
  }),
  methods: {
    async confirmDocuments() {
      this.confirmLoading = true;

      const payload = {
        status: 6
      }

     try {
       for (let i = 0; i < this.selectedClients.length; i++) {
         let client = this.selectedClients[i]
         await AdoptionsService.patchAdoption(client.adoption_id, payload)
       }
       this.getData()
     } finally {
        this.confirmLoading = false
     }

      this.$store.dispatch('snackbar/showSnackbarMessage',
        {message: 'Verification completed.Client Moved To Document Verification.', duration: 6000})
      this.selectedClients = []
    },
    async getData() {
      this.loading = true

      try {
        this.clients = await AdoptionsService.getAdoptionStatus(this.entityId, this.status)
      } finally {
        this.loading = false
      }
    }
  },
  mounted() {
    this.getData()
  }
};
</script>

<template>
  <div>
    <v-row>
      <v-col cols="12" md="10">
        <v-card>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="clients"
              show-select
              v-model="selectedClients"
              disable-pagination
              hide-default-footer
              :loading="loading"
            />
          </v-card-text>
          <v-card-actions>
            <div class="d-flex justify-center" style="width: 100%">
              <v-btn color="primary" :loading="confirmLoading" @click="confirmDocuments" :disabled="!selectedClients.length">Contact confirmed</v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>

</style>
