import $axios from "@/plugins/axios";

class AdoptionsService {
  createAdoption(payload) {
    return $axios.post('/adoptions', payload).then(res => res.data)
  }

  getAdoptions(entityId) {
    return $axios.get(`/entity/${entityId}/adoptions`).then(res => res.data)
  }

  getAdoptedClientsOfEntity(entityId) {
    return $axios.get(`/entities/${entityId}/adopted_clients`).then(res => res.data)
  }

  deleteAdoption(id) {
    return $axios.delete(`/adoptions/${id}`)
  }

  getAllAdoptions() {
    return $axios.get('/entities/adoptions').then(res => res.data)
  }

  getAdoptionStats(entityId, clientId) {
    let endpoint = `/adoptions/${entityId}/queue/stats`
    if (clientId) endpoint += `?client=${clientId}`
    return $axios.get(endpoint).then(res => res.data)
  }

  getAdoptionStatus(entityId, statusId, filter = 0) {
    const params = {
      filter
    }
    return $axios.get(`/adoption/queue/${entityId}/${statusId}`, {params}).then(res => res.data)
  }

  exportIntroLetterToCSV(entityId, statusId) {
    const params = {
      filter: 1
    }
    return $axios.get(`/adoption/queue/${entityId}/${statusId}/export`, { params }).then(res => res.data)
  }

  getAdoptionsQuarter(entityId, quarter, status){
    const params = {
      quarter,
      status
    }
    return $axios.get(`/adoptions/${entityId}/report/quarters`, {params}).then(res => res.data)
  }

  patchAdoption(adoptionId, payload) {
    return $axios.patch(`/adoptions/${adoptionId}`, payload).then(res => res.data)
  }

  searchAdoptionEmail(recipient) {
    const params = {
      q: recipient
    }
    return $axios.get('/adoptions/email/recipients', { params }).then(res => res.data)
  }

  resendAdoptionEmail(emailId) {
    return $axios.patch(`/adoptions/email/queue/${emailId}`).then(res => res.data)
  }

  sendEmails(entityId, data) {
    return $axios.post(`/adoptions/${entityId}/email`, data).then(res => res.data)
  }

  rollbackAdoption(adoptionId, newStatusId, payload) {
    return $axios.patch(`/adoptions/queue/${adoptionId}/rollback/${newStatusId}`, payload).then(res => res.data)
  }
}

export default new AdoptionsService()
