<script>
import AdoptionsService from "@/services/adoptions.service";
import MoveBackDialog from "@/components/SinglePostAdoptFirm/MoveBackDialog.vue";

export default {
  name: "DocumentVerification",
  components: { MoveBackDialog },
  props: {
    entityId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    loading: false,
    moveBackDialogVisible: false,
    status: 6,
    clients: [],
    completeLoading: false,
    headers: [
      {
        text: 'Client',
        value: 'trade_name',
        filterable: true
      },
      {
        text: 'EIN',
        value: 'ein',
        filterable: true
      },
      {
        text: 'Legal Name',
        value: 'legal_name',
        filterable: true
      },
    ],
    selectedClients: []
  }),
  methods: {
    async complete() {
      this.completeLoading = true;

      const payload = {
        status: 11
      }

      for (let i = 0; i < this.selectedClients.length; i++) {
        let client = this.selectedClients[i]
        await AdoptionsService.patchAdoption(client.adoption_id, payload)
      }

      this.$store.dispatch('snackbar/showSnackbarMessage',
        {message: 'Verification completed. Client moved to waiting Signed 8821', duration: 6000})

      this.completeLoading = false
      await this.getData()
    },
    async getData() {
      this.loading = true

      try {
        this.clients = await AdoptionsService.getAdoptionStatus(this.entityId, this.status)
      } finally {
        this.loading = false
      }
    }
  },
  async mounted() {
    await this.getData()
  }
};
</script>

<template>
  <div>
    <MoveBackDialog
      v-if="moveBackDialogVisible"
      @update:visible="event => moveBackDialogVisible = event"
      @success="getData"
      :visible="moveBackDialogVisible"
      :status="status"
      :statusName="`Document Verification`"
      :clients="selectedClients"
    />
    <v-row>
      <v-col cols="12" md="10">
        <v-card>
          <v-card-title>
            <div class="d-flex justify-end" style="width: 100%">
              <v-btn color="primary" :disabled="!selectedClients.length" @click="moveBackDialogVisible = true">
                Move Back
              </v-btn>
            </div>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="clients"
              show-select
              v-model="selectedClients"
              :loading="loading"
              disable-pagination
              hide-default-footer
            />
          </v-card-text>
          <v-card-actions>
            <div class="d-flex justify-center" style="width: 100%">
              <v-btn color="primary" :loading="completeLoading" @click="complete" :disabled="!selectedClients.length">Verification complete</v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>

</style>
